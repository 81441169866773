import React from 'react'

import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import { useAuth } from "react-oidc-context"

const BasePage = ({ children }) => {
  const auth = useAuth();

  function logoutFn(signOutFn, idToken) {
    signOutFn();
    const accountsUrl = process.env.REACT_APP_OIDC_AUTHORITY;
    const redirectUri = window.location.href;
    window.location.href = `${accountsUrl}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`;
  }

  return (
    <>
      <div className='d-flex justify-content-center mt-5'>
        <div className='d-flex flex-column'>
          <Card>
            <Card.Body>
              {children}
            </Card.Body>
          </Card>
          <br />

          <Button onClick={() => {
            logoutFn(auth.removeUser, auth.user?.id_token);
          }}>Log out</Button>
        </div>
      </div>
    </>
  )
}

BasePage.propTypes = {
  children: PropTypes.node.isRequired
}

export default BasePage
