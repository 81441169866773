import React from 'react'
import BasePage from './BasePage'

const PageNotFound = () => (
  <BasePage>
    <p>
      Page not found! 🔍
    </p>
  </BasePage>
)

export default PageNotFound
