import React, { useState } from 'react'

import { useAuth } from "react-oidc-context";
import BaseFormPage from './BaseFormPage'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { MyAccountClient } from './services/myaccount.service'

const EmailUpdate = () => {
  const [message, setMessage] = useState({ color: 'red', msg: '' })
  const [formData, setFormData] = useState({ currentEmail: '', newEmail: '' })
  const auth = useAuth();

  const handleChange = (e) => {
    e.preventDefault()
    const data = { ...formData }
    data[e.target.name] = e.target.value
    setFormData(data)
  }

  const handleClick = async (e) => {
    e.preventDefault()

    if (!formData.currentEmail || !formData.newEmail) {
      setMessage({ ...message, msg: `Falha ao alterar email: email nao informado` })
      return;
    }

    const data = {
      'currentEmail': formData.currentEmail,
      'newEmail': formData.newEmail,
    }

    try {
      await MyAccountClient.updateUserEmail(data, auth.user.access_token)
      setMessage({ color: 'green', msg: `Email atualizado com sucesso!` })
    } catch (error) {
      setMessage({ ...message, msg: `Falha ao salvar email: ${error.message}.` })
    }
  }

  console.info(auth.user);
  if (!auth.user.profile.resource_access?.['admin-app']?.roles.includes('adminapp:user-email-maintenance:write')) {
    return (
      <BaseFormPage>
        <p className='fs-3'>Acesso negado</p>
      </BaseFormPage>
    )
  }

  return (
    <BaseFormPage>
      <p className='fs-3'>Alteração de email do usuario</p>
      <Form.Group className='mb-3' controlid='formCurrentEmail'>
        <Form.Label>Email atual</Form.Label>
        <Form.Control type='email' placeholder='email@example.com' name="currentEmail" value={formData.currentEmail} onChange={handleChange} required />
      </Form.Group>

      <Form.Group className='mb-3' controlid='formNewEmail'>
        <Form.Label>Email novo</Form.Label>
        <Form.Control type='email' placeholder='email@example.com' name="newEmail" value={formData.newEmail} onChange={handleChange} required />
      </Form.Group>
      <Button variant='primary' type='submit' onClick={handleClick}>
        Salvar
      </Button>
      <p style={{ color: message.color, paddingTop: '1em' }}>{message.msg}</p>
    </BaseFormPage>
  )
}

export default EmailUpdate
