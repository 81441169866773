import { useAuth } from "react-oidc-context";
import { Outlet } from "react-router-dom";

export default function PrivateRoute({ children }) {
  const auth = useAuth();

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
    default:
      break;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }                                                                  

  if (!auth.isAuthenticated) {
    localStorage.setItem('redirect_url', window.location.pathname);
    auth.signinRedirect();
  }

  return (
    <Outlet />
  );
}