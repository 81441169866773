import axios from 'axios'

export const MyAccountClient = {
  hostDomain: process.env.REACT_APP_SELLER_URL,
  getSeller: async () => {
    const response = await axios.get('http://localhost:3000/profile')

    return response.data
  },
  buildHeaders: (accessToken) => {
    return {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  },
  makeGet: async (endpoint, accessToken) => {
    const response = await axios.get(`${MyAccountClient.hostDomain}${endpoint}`, { headers: MyAccountClient.buildHeaders(accessToken) })

    return response.data
  },
  makePatch: async (endpoint, data, accessToken) => {
    const response = await axios.patch(`${MyAccountClient.hostDomain}${endpoint}`, data, { headers: MyAccountClient.buildHeaders(accessToken) })

    return response.data
  },
  getCurrentBankAccount: async (sellerId, accessToken) => {
    const bankAccountEndpoint = `bff/my-accounts/v1/seller-bank-account/?sellerId=${sellerId}`
    return MyAccountClient.makeGet(bankAccountEndpoint, accessToken)
  },
  updateBankAccount: async (sellerId, data, accessToken) => {
    const bankAccountEndpoint = `bff/my-accounts/v1/seller-bank-account/?sellerId=${sellerId}`
    return MyAccountClient.makePatch(bankAccountEndpoint, data, accessToken)
  },
  updateUserEmail: async (data, accessToken) => {
    const userEmailEndpoint = `bff/my-accounts/v1/user-email/`
    return MyAccountClient.makePatch(userEmailEndpoint, data, accessToken)
  }
}
