import React, { useEffect } from 'react'

import EmailUpdate from './EmailUpdate'
import BankUpdate from './BankUpdate'

import { Route, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";

import { AuthProvider } from "react-oidc-context";

import PageNotFound from './PageNotFound';
import PrivateRoute from './PrivateRoute';

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: window.location.href,
  onSigninCallback: () => {
    //remove query param from url
    const url = window.location.href;
    const urlObj = new URL(url);
    urlObj.search = '';
    urlObj.hash = '';
    const result = urlObj.toString();
    window.location = result;
  },
};

function LoginCallBack() {
  const navigate = useNavigate();
  const redirect_url = localStorage.getItem('redirect_url');
  console.log('redirect_url', redirect_url);

  useEffect(() => {
    if (!redirect_url) {
      return navigate("/error");
    }

    return navigate(redirect_url);
  }, [navigate, redirect_url]);

  return <>loading...</>;
}

const App = () => {
  return (
    <AuthProvider {...oidcConfig}>
      <Router>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<LoginCallBack />} />
            <Route path="/change-email" element={<EmailUpdate />} />
            <Route path="/change-bank-account" element={<BankUpdate />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default App
