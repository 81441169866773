export const banks = {
  '001': 'Banco do Brasil S.A.',
  '003': 'Banco da Amazônia S.A.',
  '004': 'Banco do Nordeste do Brasil S.A.',
  '007': 'Banco Nacional de Desenvolvimento Econômico e Social',
  '010': 'CREDICOAMO CREDITO RURAL COOPERATIVA',
  '011': 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
  '012': 'Banco INBURSA de Investimentos S.A.',
  '014': 'Natixis Brasil S.A. Banco Múltiplo',
  '015': 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  '016': 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
  '017': 'BNY Mellon Banco S.A.',
  '018': 'Banco Tricury S.A.',
  '021': 'BANESTES S.A. Banco do Estado do Espírito Santo',
  '024': 'Banco BANDEPE S.A.',
  '025': 'Banco Alfa S.A.',
  '029': 'Banco Itaú BMG Consignado S.A.',
  '033': 'Banco Santander (Brasil) S.A.',
  '036': 'Banco Bradesco BBI S.A.',
  '037': 'Banco do Estado do Pará S.A.',
  '040': 'Banco Cargill S.A.',
  '041': 'Banco do Estado do Rio Grande do Sul S.A.',
  '047': 'Banco do Estado de Sergipe S.A.',
  '060': 'Confidence Corretora de Câmbio S.A.',
  '062': 'Hipercard Banco Múltiplo S.A.',
  '063': 'Ibibank S.A. - Banco Múltiplo',
  '064': 'Goldman Sachs do Brasil Banco Múltiplo S.A.',
  '065': 'Banco Patagon S.A.',
  '066': 'Banco Morgan Stanley S.A.',
  '069': 'Banco BPN Brasil S.A.',
  '070': 'BRB - Banco de Brasília S.A.',
  '074': 'Banco J. Safra S.A.',
  '075': 'Banco CR2 S.A.',
  '076': 'Banco KDB S.A.',
  '077': 'Banco Intermedium S.A',
  '078': 'Haitong Banco de Investimento do Brasil S.A.',
  '079': 'Banco Original do Agronegócio S.A.',
  '080': 'B&T CORRETORA DE CAMBIO LTDA.',
  '081': 'BBN Banco Brasileiro de Negócios S.A.',
  '082': 'Banco Topázio S.A.',
  '083': 'Banco da China Brasil S.A.',
  '084': 'Uniprime Norte do Paraná - Cooperativa de Crédito Ltda',
  '085': 'Cooperativa Central de Crédito Urbano - CECRED',
  '088': 'Banco Randon S.A.',
  '089': 'Cooperativa de Crédito Rural da Região da Mogiana - CREDISAN',
  '091': 'CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S',
  '092': 'BRK S.A. Crédito, Financiamento e Investimento',
  '093': 'PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
  '094': 'Banco Petra S.A.',
  '095': 'Banco Confidence de Câmbio S.A.',
  '096': 'Banco B3 S.A.',
  '097': 'Credisis - Central de Cooperativas de Crédito Ltda.',
  '098': 'Credialiança Cooperativa de Crédito Rural',
  '099': 'Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda',
  '100': 'Planner Corretora de Valores S.A.',
  '101': 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  '102': 'XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
  '104': 'Caixa Econômica Federal',
  '105': 'Lecca Crédito, Financiamento e Investimento S/A',
  '107': 'Banco BBM S.A.',
  '108': 'PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO',
  '111': 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
  '113': 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios',
  '114': 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
  '117': 'ADVANCED CORRETORA DE CÂMBIO LTDA',
  '119': 'Banco Western Union do Brasil S.A.',
  '120': 'Banco Rodobens S.A.',
  '121': 'Banco Agiplan S.A.',
  '122': 'Banco Bradesco BERJ S.A.',
  '124': 'Banco Woori Bank do Brasil S.A.',
  '125': 'Plural S.A. Banco Múltiplo',
  '126': 'BR Partners Banco de Investimento S.A.',
  '127': 'Codepe Corretora de Valores e Câmbio S.A.',
  '128': 'MS Bank S.A. Banco de Câmbio',
  '129': 'UBS Brasil Banco de Investimento S.A.',
  '130': 'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  '131': 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
  '132': 'ICBC do Brasil Banco Múltiplo S.A.',
  '133': 'Cresol Confederação',
  '134': 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  '136': 'Unicred',
  '138': 'Get Money Corretora de Câmbio S.A.',
  '139': 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
  '140': 'Easynvest - Título Corretora de Valores SA',
  '142': 'Broker Brasil Corretora de Câmbio Ltda.',
  '143': 'Treviso Corretora de Câmbio S.A.',
  '144': 'BEXS BANCO DE CÂMBIO S/A',
  '145': 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
  '146': 'GUITTA CORRETORA DE CAMBIO LTDA.',
  '149': 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
  '157': 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
  '159': 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
  '163': 'Commerzbank Brasil S.A. - Banco Múltiplo',
  '169': 'Banco Olé Bonsucesso Consignado S.A.',
  '173': 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  '174': 'PERNAMBUCANAS FINANCIADORA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  '177': 'Guide Investimentos S.A. Corretora de Valores',
  '180': 'CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  '182': 'Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento',
  '183': 'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
  '184': 'Banco Itaú BBA S.A.',
  '188': 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES',
  '189': 'HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS',
  '190': 'SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN',
  '191': 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
  '194': 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  '196': 'FAIR CORRETORA DE CAMBIO S.A.',
  '197': 'Stone Pagamentos S.A.',
  '204': 'Banco Bradesco Cartões S.A.',
  '208': 'Banco BTG Pactual S.A.',
  '211': 'Banco Sistema S.A.',
  '212': 'Banco Original S.A.',
  '213': 'Banco Arbi S.A.',
  '217': 'Banco John Deere S.A.',
  '218': 'Banco Bonsucesso S.A.',
  '222': 'Banco Credit Agricole Brasil S.A.',
  '224': 'Banco Fibra S.A.',
  '225': 'Banco Brascan  S.A.',
  '233': 'Banco Cifra S.A.',
  '237': 'Banco Bradesco S.A.',
  '241': 'Banco Clássico S.A.',
  '243': 'Banco Máxima S.A.',
  '246': 'Banco ABC Brasil S.A.',
  '248': 'Banco Boavista Interatlântico S.A.',
  '249': 'Banco Investcred S.A.',
  '250': 'BCV - Banco de Crédito e Varejo S.A.',
  '253': 'Bexs Corretora de Câmbio S/A',
  '254': 'Paraná Banco S.A.',
  '258': 'Banco Induscred S.A.',
  '259': 'MONEYCORP BANCO DE CÂMBIO S.A.',
  '260': 'Nu Pagamentos S.A.',
  '263': 'Banco Cacique S.A.',
  '265': 'Banco Fator S.A.',
  '266': 'Banco Cédula S.A.',
  '268': 'BARI COMPANHIA HIPOTECÁRIA',
  '269': 'HSBC BRASIL S.A. - BANCO DE INVESTIMENTO',
  '270': 'Sagitur Corretora de Câmbio Ltda.',
  '271': 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  '272': 'AGK CORRETORA DE CAMBIO S.A.',
  '273': 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
  '274': 'MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
  '276': 'Senff S.A. - Crédito, Financiamento e Investimento',
  '278': 'Genial Investimentos Corretora de Valores Mobiliários S.A.',
  '279': 'COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE',
  '280': 'Avista S.A. Crédito, Financiamento e Investimento',
  '281': 'Cooperativa de Crédito Rural Coopavel',
  '283': 'RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA',
  '285': 'Frente Corretora de Câmbio Ltda.',
  '286': 'COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO',
  '288': 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  '289': 'DECYSEO CORRETORA DE CAMBIO LTDA.',
  '290': 'Pagseguro Internet S.A.',
  '292': 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
  '293': 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
  '296': 'VISION S.A. CORRETORA DE CAMBIO',
  '298': "Vip's Corretora de Câmbio Ltda.",
  '299': 'SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  '300': 'Banco de La Nacion Argentina',
  '301': 'BPP Instituição de Pagamento S.A.',
  '306': 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  '307': 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
  '309': 'CAMBIONET CORRETORA DE CÂMBIO LTDA.',
  '310': 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  '315': 'PI Distribuidora de Títulos e Valores Mobiliários S.A.',
  '318': 'Banco BMG S.A.',
  '319': 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  '320': 'China Construction Bank (Brasil) Banco Múltiplo S.A.',
  '321': 'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
  '322': 'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
  '323': 'MERCADOPAGO.COM REPRESENTACOES LTDA.',
  '325': 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
  '326': 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  '329': 'QI Sociedade de Crédito Direto S.A.',
  '330': 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
  '331': 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
  '332': 'Acesso Soluções de Pagamento S.A.',
  '335': 'Banco Digio S.A.',
  '336': 'Banco C6 S.A.',
  '340': 'Super Pagamentos e Administração de Meios Eletrônicos S.A.',
  '341': 'Itaú Unibanco S.A.',
  '342': 'Creditas Sociedade de Crédito Direto S.A.',
  '343': 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
  '346': 'Banco Francês e Brasileiro S.A.',
  '348': 'Banco XP S.A.',
  '349': 'AMAGGI S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  '352': 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  '354': 'NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES',
  '355': 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
  '364': 'Gerencianet Pagamentos do Brasil',
  '366': 'BANCO SOCIETE GENERALE BRASIL S.A.',
  '370': 'Banco Europeu para a America Latina (BEAL) S.A.',
  '376': 'Banco J. P. Morgan S.A.',
  '380': 'PICPAY SERVICOS S.A.',
  '382': 'FIDUCIA SCMEPP LTDA',
  '389': 'Banco Mercantil do Brasil S.A.',
  '394': 'Banco Bradesco Financiamentos S.A.',
  '396': 'HUB PAGAMENTOS S.A',
  '399': 'HSBC Bank Brasil S.A. - Banco Múltiplo',
  '403': 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A',
  '412': 'Banco Capital S.A.',
  '422': 'Banco Safra S.A.',
  '450': 'FITBANK INSTITUIÇÃO DE PAGAMENTOS ELETRÔNICOS S.A.',
  '456': 'Banco de Tokyo-Mitsubishi UFJ Brasil S.A.',
  '461': 'Asaas',
  '464': 'Banco Sumitomo Mitsui Brasileiro S.A.',
  '473': 'Banco Financial Português S.A.',
  '477': 'Citibank N.A.',
  '479': 'Banco ItauBank S.A',
  '487': 'Deutsche Bank S.A. - Banco Alemão',
  '488': 'JPMorgan Chase Bank, National Association',
  '492': 'ING Bank N.V.',
  '494': 'Banco de La Republica Oriental del Uruguay',
  '495': 'Banco de La Provincia de Buenos Aires',
  '496': 'Banco Uno - E Brasil S.A.',
  '505': 'Banco Credit Suisse (Brasil) S.A.',
  '509': 'Celcoin Instituição de Pagamento S.A.',
  '529': 'Pinbank IP',
  '536': 'Neon',
  '545': 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
  '600': 'Banco Luso Brasileiro S.A.',
  '604': 'Banco Industrial do Brasil S.A.',
  '610': 'Banco VR S.A.',
  '611': 'Banco Paulista S.A.',
  '612': 'Banco Guanabara S.A.',
  '613': 'Banco Pecúnia S.A.',
  '623': 'Banco PAN S.A.',
  '626': 'Banco Ficsa S.A.',
  '630': 'Banco Intercap S.A.',
  '633': 'Banco Rendimento S.A.',
  '634': 'Banco Triângulo S.A.',
  '637': 'Banco Sofisa S.A.',
  '641': 'Banco Alvorada S.A.',
  '643': 'Banco Pine S.A.',
  '652': 'Itaú Unibanco Holding S.A.',
  '653': 'Banco Indusval S.A.',
  '654': 'Banco A.J.Renner S.A.',
  '655': 'Banco Votorantim S.A.',
  '658': 'Banco Porto Real S.A.',
  '707': 'Banco Daycoval S.A.',
  '712': 'Banco Ourinvest S.A.',
  '719': 'Banif-Banco Internacional do Funchal (Brasil)S.A.',
  '720': 'Banco Maxinvest S.A.',
  '725': 'Finansinos S.A. - Crédito, Financ. e Investimento',
  '737': 'Banco Theca S.A.',
  '739': 'Banco Cetelem S.A.',
  '740': 'Banco Barclays S.A.',
  '741': 'BANCO RIBEIRAO PRETO S.A.',
  '743': 'Banco Semear S.A.',
  '745': 'Banco Citibank S.A.',
  '746': 'Banco Modal S.A.',
  '747': 'Banco Rabobank International Brasil S.A.',
  '748': 'Banco Cooperativo Sicredi S.A.',
  '751': 'Scotiabank Brasil S.A. Banco Múltiplo',
  '752': 'Banco BNP Paribas Brasil S.A.',
  '753': 'Novo Banco Continental S.A. - Banco Múltiplo',
  '754': 'Banco Sistema S.A.',
  '755': 'Banco Merrill Lynch S.A.',
  '756': 'Banco Cooperativo do Brasil S.A. - BANCOOB',
  '757': 'Banco KEB HANA do Brasil S.A.',
  '735': 'Banco Pottencial S.A.'
}
