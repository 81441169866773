import React, { useState } from 'react'
import BaseFormPage from './BaseFormPage'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { banks } from './services/bank.service'

import Card from 'react-bootstrap/Card'

import { MyAccountClient } from './services/myaccount.service'

import { useAuth } from "react-oidc-context";

const BankUpdate = () => {
  const [message, setMessage] = useState({ color: 'red', msg: '' })
  const [sellerBank, setSellerBank] = useState({ name: '' })
  const [formData, setFormData] = useState({ sellerId: '', bank: '001' })
  const auth = useAuth();

  const handleChange = (e) => {
    e.preventDefault()
    const data = { ...formData }
    data[e.target.name] = e.target.value
    setFormData(data)
  }

  const handleClick = async (e) => {
    e.preventDefault()

    if (!sellerBank.id) {
      setMessage({ ...message, msg: `Falha ao salvar dados do seller: seller Nao identificado.` })
      return;
    }

    const data = {
      'id': sellerBank.id,
      'bank': formData.bank,
      'agency': formData.agency,
      'bank_account': formData.account
    }

    try {
      const sellerResponse = await MyAccountClient.updateBankAccount(formData.sellerId, data, auth.user.access_token)
      setSellerBank(sellerResponse)
      setMessage({ color: 'green', msg: `Dados bancarios atualizados com sucesso!` })
    } catch (error) {
      setSellerBank(sellerBank)
      setMessage({ ...message, msg: `Falha ao salvar dados do seller: ${error.message}.` })
    }
  }

  const findSeller = async () => {
    try {
      const sellerResponse = await MyAccountClient.getCurrentBankAccount(formData.sellerId, auth.user.access_token)
      setSellerBank(sellerResponse)
    } catch (error) {
      setMessage({ ...message, msg: `Falha ao buscar dados do seller: ${error.message}.` })
    }
  }

  if (!auth.user.profile.resource_access?.['admin-app']?.roles.includes('adminapp:seller-bank-maintenance:write')) {
    return (
      <BaseFormPage>
        <p className='fs-3'>Acesso negado</p>
      </BaseFormPage>
    )
  }

  return (
    <BaseFormPage>
      <>
        <p className='fs-3'>Alteração de dados bancarios do usuario</p>

        <p>Id do seller</p>
        <InputGroup className='mb-3' controlid='formSellerId'>
          <Form.Control type='text' placeholder='00000000-0000-0000-0000-000000000000' required name="sellerId" value={formData.sellerId} onChange={handleChange} />
          <Button variant='outline-secondary' id='button-addon2' onClick={findSeller}>
            🔍
          </Button>
        </InputGroup>

        <Card className='mb-3'>
          <Card.Body>
            <p className='fs-5'>Dados atuais do seller</p>
            email: {sellerBank.email}<br />
            Banco: {sellerBank.bank}<br />
            Agencia: {sellerBank.agency}<br />
            Conta: {sellerBank.bank_account}<br />
          </Card.Body>
        </Card>

        <Form.Label>Banco</Form.Label>
        <Form.Select aria-label='one um banco' required name="bank" onChange={handleChange}>
          {Object.keys(banks).sort().map((key) => {
            return (
              <option key={key} value={key}>{`${key} - ${banks[key]}`}</option>
            )
          })}
        </Form.Select>

        <Form.Group className='mb-3' controlid='formBankAgency'>
          <Form.Label>Agencia</Form.Label>
          <Form.Control type='text' placeholder='1234' required name="agency" value={formData.agency} onChange={handleChange} />
        </Form.Group>

        <Form.Group className='mb-3' controlid='formBankAccount'>
          <Form.Label>Conta</Form.Label>
          <Form.Control type='text' placeholder='00000-0' required name="account" value={formData.account} onChange={handleChange} />
        </Form.Group>

        <Button variant='primary' type='submit' onClick={handleClick}>
          Salvar
        </Button>
        <p style={{ color: message.color, paddingTop: '1em' }}>{message.msg}</p>
      </>
    </BaseFormPage>
  )
}

export default BankUpdate
