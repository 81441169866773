import React, { useState } from 'react'

import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

import BasePage from './BasePage'

const style = {
  width: '30em'
}

const BaseFormPage = ({ children }) => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)
  }

  return (
    <BasePage>
      <Form style={style} noValidate validated={validated} onSubmit={handleSubmit}>
        {children}
      </Form>
    </BasePage>
  )
}

BaseFormPage.propTypes = {
  children: PropTypes.node.isRequired
}

export default BaseFormPage
